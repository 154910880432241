import React, { useState } from 'react';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getSettings, getToken, getUser } from '../../redux/slices/applicationSlice';
// import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ReactComponent as VisaIcon } from '../../assets/img/icons/visa.svg';
import { ReactComponent as CardIcon } from '../../assets/img/icons/card.svg';
import { ReactComponent as MastercardIcon } from '../../assets/img/icons/mastercard.svg';
import { ReactComponent as AmexIcon } from '../../assets/img/icons/amex.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/img/icons/discover.svg';
import { ReactComponent as Loader } from '../../assets/img/icons/loader.svg';
import { ReactComponent as DinersIcon } from '../../assets/img/icons/diners.svg';
import { ReactComponent as JcbIcon } from '../../assets/img/icons/jcb.svg';
import { ReactComponent as UnionpayIcon } from '../../assets/img/icons/unionpay.svg';
import { ReactComponent as PlusIcon } from '../../assets/img/icons/plus.svg';
import Input from '../../components/base/Input';
import classNames from 'classnames';
import PrimaryButton from '../../components/base/PrimaryButton';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import creditCardType from 'credit-card-type';
import { City, Country, State } from 'country-state-city';
import SelectBox from '../../components/base/SelectBox';

export const brandIcons = {
    unknown: CardIcon,
    visa: VisaIcon,
    Visa: VisaIcon,
    MasterCard: MastercardIcon,
    mastercard: MastercardIcon,
    'american-express': AmexIcon,
    discover: DiscoverIcon,
    Discover: DiscoverIcon,
    'diners-club': DinersIcon,
    jcb: JcbIcon,
    JCB: JcbIcon,
    unionpay: UnionpayIcon
}

const isValidZipCode = (zipCode) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

const AddCardModal = ({ close, cards, setCards }) => {
    const token = useSelector(getToken);
    const user = useSelector(getUser);

    // const elements = useElements();
    // const stripe = useStripe();

    const [country, setCountry] = useState('US');
    const [state, setState] = useState(null);
    const [brand, setBrand] = useState('unknown');
    // const CurrentIcon = brandIcons[brand];
    const [cardData, setCardData] = useState({
        cardNumber: '',
        expiryDate: '',
        cardCode: '',
        // fullName: '',
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        address: '',
        zip: '',
        country: 'United States',
        isDefault: true
    });
    console.log(cardData);
    const types = creditCardType(cardData.cardNumber);
    const CurrentIcon = cardData.cardNumber ? brandIcons[types?.[0]?.type] || brandIcons.unknown : brandIcons.unknown;
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // const handleAddCard = async () => {
    //     if(!isValidZipCode(cardData.zipCode)) {
    //         toast.error('Zip Code must be a 5 digit number.');
    //         return;
    //     }
    //     try {
    //         const toastId = toast.loading("Adding card...");
    //         const intent = await api.payments.createIntent({token});
    //         const stripeData = await stripe.createPaymentMethod({
    //             type: 'card',
    //             card: elements.getElement('cardNumber'),
    //             billing_details: {
    //                 name: cardData.name,
    //                 email: user.email,
    //                 address: {
    //                     postal_code: cardData.zipCode
    //                 }
    //             },
    //         });
    //         if(stripeData.error) {
    //             toast.update(toastId, {
    //                 render: stripeData.error.message, 
    //                 type: 'error', 
    //                 isLoading: false,
    //                 autoClose: 5000
    //             });
    //             return;
    //         };
    //         const dataConfirm = await stripe.confirmCardSetup(intent.client_secret, {
    //             payment_method: stripeData.paymentMethod.id
    //         });
    //         if (dataConfirm.error) {
    //             toast.update(toastId, {
    //                 render: dataConfirm.error.message, 
    //                 type: 'error', 
    //                 isLoading: false,
    //                 autoClose: 5000
    //             });
    //             return
    //         };
    //         const response = await api.paymentCards.addCard({
    //             data: {
    //                 payment_method: dataConfirm.setupIntent.payment_method,
    //                 intentId: intent,
    //                 isDefault: cardData.setDefault
    //             },
    //             token
    //         });
    //         console.log(response);
    //         toast.update(toastId, {
    //             render: response.success ? 'Card added successfully!' : response.message, 
    //             type: response.success ? 'success' : 'error', 
    //             isLoading: false,
    //             autoClose: 5000
    //         });
    //         if(!response.success) return;
    //         close();
    //         api.paymentCards.getCards({token}).then(response => {
    //             if(!response.success) return;
    //             setCards(response.data);
    //         })
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    const AllCountries = Country.getAllCountries()
   
    const countryOptions = AllCountries.map((country) => ({
        label: country.name,
        value: country.isoCode
    }))

    const AllStates = State.getStatesOfCountry(country)
    const stateOptions = AllStates.map((state) => ({
        label: state.name,
        value: state.isoCode
    }))

    const AllCities = City.getCitiesOfState(country, state)
    const cityOptions = AllCities.map((city) => ({
        label: city.name,
        value: city.name
    }))
    const handleAddCard = async () => {
        if (!cardData.firstName) {
            toast.error('First Name is requiered');
            return;
        }
        if (!cardData.lastName) {
            toast.error('Last Name is requiered');
            return;
        }
        if (!cardData.address) {
            toast.error('Address is requiered');
            return;
        }
        if (!cardData.city) {
            toast.error('City is requiered');
            return;
        }
        if (!cardData.state) {
            toast.error('State is requiered');
            return;
        }
        if (!cardData.zip) {
            toast.error('zip is requiered');
            return;
        }
        if (!cardData.country) {
            toast.error('Country is requiered');
            return;
        }
        if (cardData.cardNumber.length < 13) {
            toast.error('Enter a valid credit card number');
            return;
        }
        if (cardData.expiryDate.length < 7) {
            toast.error('Enter a valid expiration date');
            return;
        }
        if (cardData.cardCode.length < 3) {
            toast.error('Enter a valid CVV');
            return;
        }

        try {
            const toastId = toast.loading("Adding card...");
            const [expMonth, expYear] = cardData.expiryDate.split(' / ');
            const response = await api.payAuthorize.addCard({
                data: {
                    ...cardData,
                    expMonth,
                    expYear
                },
                token
            });
            toast.update(toastId, {
                render: response.success ? 'Card added successfully!' : String(response.message),
                type: response.success ? 'success' : 'error',
                isLoading: false,
                autoClose: 5000
            });
            if (!response.success) return;
            close();
            api.paymentCards.getCards({ token }).then(response => {
                if (!response.success) return;
                setCards(response.data);
            })
        } catch (e) {
            console.log(e);
        }
    }
    return <div className='modal-content relative p-[36px]'>
        <button
            className="z-10 absolute top-[16px] right-[16px] w-[34px] h-[34px] flex items-center justify-center
            bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333]"
            onClick={close}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g opacity="0.5">
                    <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            </svg>
        </button>
        <div className='flex flex-col gap-[36px]'>
            <div>
                <h2 className='modal-body-title'>Add Card</h2>
                <span className='modal-body-subtitle' >Please fill in form below to add new card.</span>
                <div>
                    <span className='modal-body-subtitle' >Credit Card Billing Address</span>
                </div>
            </div>
            <div className='flex flex-col gap-[16px]'>
                <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>First Name</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='John'
                            value={cardData.firstName}
                            onChange={(e) => setCardData({ ...cardData, firstName: e.target.value })}
                            autoComplete='cc-name'
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Last Name</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='Smith'
                            value={cardData.lastName}
                            onChange={(e) => setCardData({ ...cardData, lastName: e.target.value })}
                            autoComplete='cc-name'
                        />
                    </div>
                </div>

                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Address</p>
                    <Input
                        className="min-h-[40px]"
                        placeholder='Address'
                        value={cardData.address}
                        onChange={(e) => setCardData({ ...cardData, address: e.target.value })}
                    // autoComplete='cc-name'
                    />
                </div>
                <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Country</p>
                        {/* <Input
                            className="min-h-[40px]"
                            placeholder='Country'
                            value={cardData.country}
                            onChange={(e) => setCardData({...cardData, country: e.target.value})}
                            // autoComplete='cc-name'
                        /> */}
                        <SelectBox
                            className='default-select flex-1 w-auto'
                            isSearchable={true}
                            value={country}
                            change={(option) => {

                                setCountry(option)
                                setState(null)
                                const country = Country.getCountryByCode(option)

                                setCardData({ 
                                    ...cardData, 
                                    country: country?.name,
                                    state: '',
                                    city: ''
                                })
                            }}
                            propsOptions={countryOptions}
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>State</p>
                        {/* <Input
                            className="min-h-[40px]"
                            placeholder='State'
                            value={cardData.state}
                            onChange={(e) => setCardData({ ...cardData, state: e.target.value })}
                        // autoComplete='cc-name'
                        /> */}
                        <SelectBox
                            className='default-select flex-1 w-auto'
                            isSearchable={true}
                            value={state}
                            change={(option) => {

                                setState(option)
                                const state = State.getStateByCodeAndCountry(option, country)
                                console.log(state)
                                setCardData({ 
                                    ...cardData, 
                                    state: state?.name, 
                                    city: ''
                                })
                            }}
                            propsOptions={stateOptions}
                            placeholder={'State'}
                        />
                    </div>


                </div>
                <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>City</p>
                        {/* <Input
                            className="min-h-[40px]"
                            placeholder='City'
                            value={cardData.city}
                            onChange={(e) => setCardData({ ...cardData, city: e.target.value })}
                        // autoComplete='cc-name'
                        /> */}
                        <SelectBox
                            className='default-select flex-1 w-auto'
                            isSearchable={true}
                            value={cardData.city}
                            change={(option) => {
                                setCardData({ ...cardData, city: option })
                            }}
                            propsOptions={cityOptions}
                            placeholder={'City'}
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>ZIP</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='zip'
                            value={cardData.zip}
                            onChange={(e) => setCardData({ ...cardData, zip: e.target.value })}
                        // autoComplete='cc-name'
                        />
                    </div>
                </div>

                <hr style={{ backgroundColor: '#333', height: '1px' }} />

                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Card Number</p>
                    <div className='py-[8px] pl-[12px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                    flex items-center gap-[12px] border border-solid border-[#333]'>
                        <CurrentIcon className='w-[28px] h-[20px]' />
                        {/* <CardNumberElement
                            className='block flex-1' 
                            options={{
                                style: {
                                    base: {
                                        color: '#ffffffe6',
                                        fontSize: '14px',
                                        backgroundColor: '#1B1B1B',
                                    },
                                },
                            }}               
                            onChange={(e) => {
                                if(!e.brand) return;
                                console.log(e.brand);
                                if(!brandIcons[e.brand]) {
                                    setBrand('unknown')
                                } else {
                                    setBrand(e.brand);
                                }
                            }}
                        /> */}
                        {/* <input
                            placeholder='12345'
                            className='bg-inherit text-[14px] text-[white] opacity-90'
                            inputMode="numeric"
                            type='text'
                            maxLength="10"
                            value={cardData.cardNumber}
                            onChange={(e) => setCardData({...cardData, cardNumber: e.target.value})}
                        /> */}
                        <ReactInputMask
                            mask="9999 9999 9999 9999"
                            inputMode="numeric"
                            placeholder="1234 1234 1234 1234"
                            type='tel'
                            className='bg-inherit text-[14px] text-[white] opacity-90'
                            maskChar={null}
                            value={cardData.cardNumber}
                            onChange={(e) => setCardData({ ...cardData, cardNumber: e.target.value.replace(/\s/g, '') })}
                            autoComplete='cc-number'
                        />
                    </div>
                </div>
                <div className='flex gap-[16px]'>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Expiration Date</p>
                        <div className='py-[8px] pl-[14px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                        flex items-center border border-solid border-[#333]'>
                            {/* <CardExpiryElement
                                className='block flex-1'
                                options={{
                                    style: {
                                        base: {
                                            color: '#ffffffe6',
                                            fontSize: '14px',
                                            backgroundColor: '#1B1B1B',
                                        },
                                    },
                                }}
                            /> */}
                            <ReactInputMask
                                mask="99 / 99"
                                inputMode="numeric"
                                placeholder="MM / YY"
                                type='tel'
                                className='bg-inherit text-[14px] text-[white] opacity-90'
                                maskChar={null}
                                value={cardData.expiryDate}
                                onChange={(e) => setCardData({ ...cardData, expiryDate: e.target.value })}
                                autoComplete='cc-exp'
                            />
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>CVV</p>
                        <div className='py-[8px] pl-[14px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                        flex items-center border border-solid border-[#333]'>
                            {/* <CardCvcElement
                                className='block flex-1'
                                options={{
                                    style: {
                                        base: {
                                            color: '#ffffffe6',
                                            fontSize: '14px',
                                            backgroundColor: '#1B1B1B',
                                        },
                                    },
                                    placeholder: '123'
                                }}

                            /> */}
                            <ReactInputMask
                                mask="999"
                                inputMode="numeric"
                                placeholder="123"
                                type='tel'
                                className='bg-inherit text-[14px] text-[white] opacity-90'
                                maskChar={null}
                                value={cardData.cardCode}
                                onChange={(e) => setCardData({ ...cardData, cardCode: e.target.value })}
                                autoComplete='cc-csc'

                            />
                        </div>
                    </div>
                    {/* <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Zip Code</p>
                        <Input
                            placeholder='12345'
                            className='w-full'
                            inputMode="numeric"
                            type='text'
                            maxLength="10"
                            value={cardData.zipCode}
                            onChange={(e) => setCardData({...cardData, zipCode: e.target.value})}
                        />
                    </div> */}
                </div>
                <label className="custom-checkbox">
                    <input
                        type="checkbox"
                        className="hidden"
                        checked={cardData.isDefault}
                        onChange={(e) => setCardData({ ...cardData, isDefault: e.target.checked })}
                        disabled={cards.length === 0}
                    />
                    <span className="custom-checkbox-fake"></span>
                    <span className="custom-checkbox-text text-[#FFFFFF]">Set as Default</span>
                </label>
            </div>

            <PrimaryButton
                onClick={handleAddCard}
                // disabled={cardData.cardNumber.length !== 16 || !cardData.fullName || cardData.expiryDate.length !== 7 || cardData.cardCode.length !== 3}
                className='w-fit ml-auto stroke-plus'
            >
                <PlusIcon className='icon-plus-card' />
                <span>Add Card</span>
            </PrimaryButton>
        </div>
    </div>
}

export default AddCardModal;
import React, { useEffect, useState } from 'react'
import { course, getCourseCompeteTime, newsletters, generatedDate, } from './consts'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import GoalGeneratorCard from '../GoalGeneratedContent/GoalGeneratorCard'
import LessonCard from './LessonCard'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getGeneratedCourses, setCourseLessons } from '../../../redux/slices/courseGeneratorSlice'
import { useDispatch } from 'react-redux'
import { getToken } from '../../../redux/slices/applicationSlice'
import api from '../../../api'

export default function Lessons({cardFullScreen, setCardFullScreen, mobileScreen = false, openSettingsModal}) {
    const { id } = useParams();
    const token = useSelector(getToken);
    const courses = useSelector(getGeneratedCourses);
    const courseIndex = courses?.findIndex(course => String(course.id) === id);
    const course = courses?.[courseIndex];
    const navigate = useNavigate();
    const [image, setImage] = useState(null)

    const dispatch = useDispatch();
    const handleDragEnd = (result) => {
        const {destination,source} = result;
        if (!destination) return;
        const resultTasks = [...course?.lessons];
        const [reOrdered] = resultTasks.splice(source.index, 1);
        resultTasks.splice(destination.index, 0, reOrdered);
        dispatch(setCourseLessons({
            courseIndex,
            data: resultTasks
        })); 
    }

    const deleteCourseHandler = async () => {
        const res = await api.courses.deleteCourse({id, token});
        if(res?.success){
            navigate("/my-lessons");
        }
    }

    useEffect(()=>{
        if(course?.cover && typeof course?.cover !== 'string'){
            setImage(URL.createObjectURL(course.cover));
        } else if(course?.cover && typeof course?.cover === 'string'){
            setImage(`${process.env.REACT_APP_API_URL}${course.cover}`)
        }
    }, [course]);

    return <>
        <div className='py-[13px] px-[16px] flex items-center justify-between'>
            <div className='flex items-center gap-[14px] min-h-[35px]'>
                <Link to='/generated-page/courses?type=lesson-kit'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M5 12H19" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 7L5 12" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 17L5 12" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Link>
                {
                    image ? <img 
                        src={image}
                        alt='img'
                        className='rounded-[6px] w-[50px] max-h-[35px] h-full'
                    /> : <div className="rounded-[6px] w-[50px] min-h-[35px] h-[35px] h-full bg-[#333333] text-[8px] d-flex items-center justify-center opacity-30">
                        No Image
                    </div>
                }
                <div>
                    <h1 className='text-[14px] lg:text-[14px] font-medium leading-normal'>{mobileScreen ? `${(course?.title || course?.text)?.slice(0, 20)}...` : (course?.title || course?.text)}</h1>
                    <div className='text-[10px] lg:text-[12px] leading-[14px] opacity-40 flex items-center gap-[5px] mob-flex-wrap'>
                        {course?.lessons?.length} lessons
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        {getCourseCompeteTime(course?.lessons)}
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        Generated: { course?.generated || course?.updatedAt ? generatedDate(course?.generated || course?.updatedAt) : '--.--.----, --:--' } 
                    </div>
                </div>
            </div>
            <div className='flex gap-[4px] lg:gap-[8px] flex-col lg:flex-row ml-[10px] lg:ml-[0px]'>
                <button 
                    className='opacity-50'
                    onClick={() => {
                        openSettingsModal(
                            {
                                id: course?.id,
                                title: course?.title,
                                description: course?.description,
                                lessons: course?.lessons,
                                isDone: true,
                                settings: {
                                    method: course?.method,
                                    text: course?.text,
                                    backgroundInformation: course?.backgroundInformation,
                                    language: course?.language,
                                    country: course?.country,
                                    numberOfLessons: course?.numberOfLessons,
                                    lessonType: course?.lessonType,
                                    subjectLevel: course?.subjectLevel,
                                    readingLevel: course?.readingLevel,
                                    specialEducation: course?.specialEducation,
                                    competencyMapping: course?.competencyMapping,
                                    addons: course?.addons,
                                    scheduleLessonFrequency: course?.scheduleLessonFrequency,
                                    scheduleRepeatOn: course?.scheduleRepeatOn,
                                    scheduleBegins: course?.scheduleBegins,
                                    scheduleTime: course?.scheduleTime,
                                    scheduleIsSendOneMessage: course?.scheduleIsSendOneMessage,
                                    haveSettings: true,
                                    cover: course?.cover,
                                },
                                materials: course?.materials,
                                materials_files: []
                            }
                        )
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.8029 2.99609C13.225 2.99609 13.6017 3.26112 13.7443 3.65837L14.2525 5.07396C14.3332 5.29657 14.4898 5.4836 14.6947 5.60218L16.2024 6.47254C16.4071 6.59066 16.647 6.63248 16.8796 6.59059L18.3603 6.32348C18.776 6.24788 19.1945 6.44168 19.4057 6.80768L20.206 8.18826C20.4178 8.55326 20.3778 9.01193 20.106 9.33473L19.1346 10.4832C18.9816 10.6638 18.8976 10.8928 18.8975 11.1295V12.8702C18.8976 13.1069 18.9816 13.3359 19.1346 13.5165L20.106 14.665C20.3778 14.9878 20.4178 15.4464 20.206 15.8114L19.4057 17.192C19.1946 17.5576 18.7767 17.7514 18.3613 17.6762L16.8806 17.4091C16.648 17.3672 16.4081 17.409 16.2034 17.5271L14.6957 18.3975C14.4908 18.5161 14.3342 18.7031 14.2535 18.9257L13.7453 20.3413C13.6025 20.7389 13.2254 21.004 12.8029 21.0036H11.2023C10.7802 21.0036 10.4036 20.7386 10.2609 20.3413L9.75267 18.9257C9.67203 18.7034 9.51589 18.5165 9.31149 18.3975L7.80286 17.5271C7.59814 17.409 7.3582 17.3672 7.12558 17.4091L5.64496 17.6762C5.2292 17.7518 4.81077 17.558 4.59953 17.192L3.7992 15.8114C3.58739 15.4464 3.62742 14.9878 3.89924 14.665L4.87064 13.5165C5.02364 13.3359 5.10765 13.1069 5.10774 12.8702V11.1295C5.10765 10.8928 5.02364 10.6638 4.87064 10.4832L3.90924 9.33473C3.63742 9.01193 3.5974 8.55326 3.8092 8.18826L4.60953 6.80768C4.82064 6.44207 5.23853 6.24833 5.65397 6.32348L7.13458 6.59059C7.3672 6.63248 7.60714 6.59066 7.81187 6.47254L9.32049 5.60218C9.52489 5.48322 9.68103 5.29628 9.76168 5.07396L10.2699 3.65837C10.4114 3.26421 10.7835 2.99993 11.2023 2.99609H12.8029Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <circle cx="12.0011" cy="12.0002" r="2.75115" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <button 
                    className='opacity-50 hidden lg:block'
                    onClick={deleteCourseHandler}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.1368 21H7.85684C6.80984 21 5.93984 20.192 5.86184 19.147L4.96484 7H18.9988L18.1318 19.142C18.0568 20.189 17.1858 21 16.1368 21V21Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 11V17" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 7H20" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M17 7L15.987 4.298C15.694 3.517 14.948 3 14.114 3H9.886C9.052 3 8.306 3.517 8.013 4.298L7 7" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.4298 11L14.9998 17" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.57016 11L9.00016 17" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className='w-[1px] h-[24px] rounded-full bg-[#1F1F1F] hidden lg:block'/>
                <button className='opacity-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.6875 9.68482C9.92452 8.66326 10.855 7.95496 11.9028 7.99844C13.0754 7.93349 14.0807 8.82709 14.1537 9.99928C14.1537 11.5038 12.0028 12.0001 12.0028 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
        <div className='py-[4px] px-[16px] flex-1 gap-[12px] flex flex-col overflow-y-scroll no-scroll'>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="course-generator-lessons" direction="vertical">
                    {(provided) => <ul ref={provided.innerRef} {...provided.droppableProps}>
                        {course?.lessons.map((lesson, index) => 
                            <Draggable
                                draggableId={String(lesson.title)} 
                                index={index} 
                                key={`${lesson.title.title}-${index}`}
                            >
                                {(provided) => 
                                    <LessonCard
                                        // currentTask={milestone}
                                        index={index}
                                        courseIndex={courseIndex}
                                        provided={provided}
                                        lesson={{
                                            ...lesson,
                                            materials: course.materials
                                        }}
                                        cardFullScreen={cardFullScreen}
                                        setCardFullScreen={setCardFullScreen}
                                    />
                                }
                            </Draggable>
                        )}
                        {provided.placeholder}
                    </ul>}
                </Droppable>          
            </DragDropContext>
        </div>
    </>
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    courses: [],
    activeCourseIndex: 0,
}

const courseGeneratorSlice = createSlice({
    name: 'courseGenerator',
    initialState,
    reducers: {
        setCourses: (state, action) => {
            // console.log('setCourses:', action.payload);
            state.courses = action.payload;
        },
        setCourseLessonContent: (state, {payload}) => {
            state.courses[payload.courseIndex].lessons[payload.lessonIndex].content = payload.data;
        },
        setCourseLessonAddon: (state, {payload}) => {
            state.courses[payload.courseIndex].lessons[payload.lessonIndex].addons = {
                ...state.courses[payload.courseIndex].lessons[payload.lessonIndex].addons,
                ...payload.data
            };
        },
        setCourseMaterial: (state, {payload}) => {
            state.courses[payload.courseIndex].materials[payload.materialIndex] = payload.data;
        },
        setCourseLessonMaterial: (state, {payload}) => {
            state.courses[payload.courseIndex].lessons[payload.lessonIndex].materials[payload.materialIndex] = payload.data;
        },
        setCourseLessons: (state, {payload}) => {
            state.courses[payload.courseIndex].lessons = payload.data;
        },
        setActiveCourseIndex: (state, action) => {
            state.activeCourseIndex = action.payload;
        },
        deleteCourseMaterial: (state, {payload}) => {
            state.courses[payload.courseIndex].materials = state.courses[payload.courseIndex].materials.filter((material) => material.id !== payload.id);
        },
        setUpdateCourseMaterial: (state, {payload}) => {
            const materialIndex = state.courses[payload.courseIndex].materials.findIndex((material) => material.id === payload.id);
            console.log(materialIndex)
            if(materialIndex >= 0) {
                state.courses[payload.courseIndex].materials[materialIndex] = payload.data;
            }
        },
        deleteCourseLesson: (state, {payload}) => {
            state.courses[payload.courseIndex].lessons.splice(payload.lessonIndex, 1);
        },
        copyCourseLesson: (state, {payload}) => {
            state.courses[payload.courseIndex].lessons.push(state.courses[payload.courseIndex].lessons[payload.lessonIndex]);
        },
    }
})


export const {
    setCourses, setActiveCourseIndex, setCourseLessonContent, deleteCourseLesson,
    copyCourseLesson, setCourseLessons, setCourseLessonAddon, setCourseLessonMaterial,
    setCourseMaterial,
    deleteCourseMaterial,
    setUpdateCourseMaterial
} = courseGeneratorSlice.actions

export const getGeneratedBlogs = (state) => state.blogGenerator.blogs;
export const getGenerateLoading = (state) => state.blogGenerator.generateLoading;
export const getActiveBlogIndex = (state) => state.blogGenerator.activeBlogIndex;
export const getBlogGeneratingContent = (state) => state.blogGenerator.generatingContent;
export const getBlogActiveVersion = ({blogGenerator: {blogs, activeBlogIndex}}) => {
    const activeBlog = blogs[activeBlogIndex];
    return activeBlog.versions[activeBlog.activeVersionIndex];
}

export const getGeneratedCourses = (state) => state.courseGenerator.courses;

export default courseGeneratorSlice.reducer
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as CrossIcon } from './icons/cross.svg';

const MaterialItem = ({ value, activeBtn, setActiveBtn, updateMaterial ,selectedMaterialValue ,setSelectedMaterialValue }) => {
    const contentRef = useRef(null);
    const textAreaRef = useRef(null);
    const [height, setHeight] = useState('auto');
       
    useEffect(() => {
        if(contentRef.current) {
            setHeight(`${contentRef.current.clientHeight + 20}px`);
        }
        
    }, [contentRef, value])
    return (
        <div className="flex flex-col gap-3">
            <span
                ref={contentRef}
                // contentEditable={activeBtn === 'edit-matherial' && selectedMaterialValue.id === item.id}
                // onInput={({ target }) => {
                //     setSelectedMaterialValue(prev => ({
                //         ...prev,
                //         content: target.innerHTML
                //     })
                //     )
                // }}
                className={`text-[14px] text-orange-primary underline 
                    ${activeBtn === 'edit-matherial' && selectedMaterialValue.id === value?.id ? 'hidden' : ''}`
                }
            >
                {value?.content}
            </span>
            {activeBtn === 'edit-matherial' && selectedMaterialValue.id === value?.id &&
                <>
                    <textarea
                        className='goal-generator-input --type-textarea'
                        value={selectedMaterialValue?.content}
                        ref={textAreaRef}
                        // cols={4}
                        style={{ minHeight: height }}
                        placeholder="Write name for add-on that you will add..."
                        onChange={({ target }) =>
                            setSelectedMaterialValue(prev => ({
                                ...prev,
                                content: target.value
                            })
                            )
                        }
                    />
                    <div className="flex items-ceter gap-3">
                        <button
                            className='goal-generator-action btn--primary-gradient btn'
                            onClick={() => {
                                updateMaterial(selectedMaterialValue?.id)
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 4.16699V15.8337" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.16797 10H15.8346" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Save Changes
                        </button>
                        <button
                            className='goal-generator-button-cross'
                            onClick={() => {
                                setActiveBtn(null);
                                setSelectedMaterialValue(null);
                            }}
                        >
                            <CrossIcon />
                        </button>
                    </div>
                </>  
                
            }
        </div>
    )
}

export default MaterialItem

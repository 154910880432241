import React, { Fragment, useContext, useEffect, useState } from 'react';
import { format, set } from 'date-fns';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { ReactComponent as DragIcon } from './icons/drag.svg';
import { ReactComponent as AwesomeIcon } from './icons/awesome.svg';
import { ReactComponent as CrossIcon } from './icons/cross.svg';
import { ReactComponent as RefreshIcon } from './icons/refresh.svg';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { defaultButtons, editButtons, labelSuggestions } from './consts';
import InputSuggestion from '../../GeneratorV4/InputSuggestion';
import TextareaGenerator from '../../GeneratorV4/TextareaGenerator';
import { GenerateFormContext } from '../../GeneratorV4/GeneratorContextComponent';
import CardLoader from './CardLoader';
import DatePicker from '../../../components/base/DatePicker';
import Tippy from '@tippyjs/react';
import GoalInputSuggestion from '../../GeneratorV4/GoalInputSuggestion';

import { useDispatch, useSelector } from 'react-redux';
import { getSocket, getToken } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import PrimaryButton from '../../../components/base/PrimaryButton';

import GeneratedContentModal from '../GeneratedContentModal';
import api from '../../../api';
import { useQuill } from 'react-quilljs';
import EditorToolbar from '../Blog/EditorToolbar';
import { deleteCourseMaterial, setCourseLessonAddon, setCourseLessonContent, setCourseLessonMaterial, setCourseMaterial, setUpdateCourseMaterial, updateCourseMaterial } from '../../../redux/slices/courseGeneratorSlice';
import DefaultToolbar from './DefaultToolbar';
import EditorControl from './EditorControl';
import { db } from '../../../db';
import { useLiveQuery } from 'dexie-react-hooks';
import SelectBox from '../../../components/base/SelectBox';

import { toast } from 'react-toastify';
import AddEditorChartModal from '../../../components/parts/AddEditorChartModal';
import MaterialItem from './MaterialItem';

const LessonCard = ({ lesson, index, courseIndex, provided, cardFullScreen, setCardFullScreen }) => {
    const { id } = useParams();
    const token = useSelector(getToken);
    const socket = useSelector(getSocket);

    const [isOpen, setIsOpen] = useState(false);
    const [activeBtn, setActiveBtn] = useState(null);
    const [selectedMaterialValue, setSelectedMaterialValue] = useState('');
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        deadline: '',
    });

    const [customPrompt, setCustomPrompt] = useState('');

    const [labelInput, setLabelInput] = useState('');
    const [subtaskInput, setSubtaskInput] = useState('');
    const [labelInputState, setLabelInputState] = useState('input');
    const [draftLabels, setDraftLabels] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [suggestedSubtaskTools, setSuggestedSubtaskTools] = useState([]);
    const [selectedSuggestedSubtaskTools, setSelectedSuggestedSubtaskTools] = useState(null);

    const [openedModal, setOpenedModal] = useState(null);

    const courseFromIndexedDb = useLiveQuery(
        () => db?.courses?.get(Number(id)), [id]
    );

    const editLesson = ({ operation, customPayload }) => {
        socket.send({
            event: 'lesson-kit-edit',
            payload: {
                operation,
                content: lesson.content,
                ...customPayload
            },
            handler: (response) => {
                if (response?.isError) {
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                if (response?.isDone) {
                    dispatch(getUserUpdateThunk({ token }));
                }

                dispatch(setCourseLessonContent({
                    courseIndex,
                    lessonIndex: index,
                    data: response.data
                }))
                if (response.done) {
                    db.courses.update(Number(id), {
                        [`lessons.${index}.content`]: response.data
                    })
                }
            }
        });
    }

    const updateIndexedDB = async () => {
        await db.courses.put({
            id: 4,
            test: 'test'
        })
    }
    const dispatch = useDispatch();


    const customPromptSubmit = () => {
        // setIsLoading(true);

        editLesson({
            operation: 'custom',
            customPayload: {
                data: customPrompt
            }
        })

        setActiveBtn(null);
        setCustomPrompt('');
    }

    const saveChanges = () => {
        // dispatch(updateGoalTask({
        //     index,
        //     data: {
        //         task_title: formData.title,
        //         task_description: formData.description,
        //         deadline: String(formData.deadline)
        //     }
        // }))
    }
    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
            toolbar: '#toolbar' + index,
            //   history: {
            //     delay: 2000,
            //     userOnly: true
            //   },
            imageUploader: {
                upload: (file) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const { data: { attachments }, success } = await api.assets.changeAsset({
                                id,
                                data: {
                                    attachments: file
                                },
                                token
                            })
                            if (success) {
                                resolve(process.env.REACT_APP_API_URL + attachments[attachments.length - 1].path);
                            }
                            reject("Upload failed");
                        } catch (e) {
                            reject("Upload failed");
                        }
                    });
                },
            }
        },
        formats: [
            'bold', 'italic', 'underline', 'align', 'link', 'image', 'video',
            'color', 'background', 'header', 'font', 'size',
            'clean', 'imageBlot', 'list',
        ],
    });

    const addMaterial = () => {
        if (addonType === '') {
            toast.info('Please Select add-on type')
            return
        }
        const toastId = toast.loading('Material is Adding')
        socket.send({
            event: 'lesson-kit-addons',
            payload: {
                title: lesson.title,
                content: lesson.content,
                addons: [{ title: addonType, key: addonType.replace(/ /g, '_').toLowerCase() }],
            },
            handler: (response) => {

                if (response?.isError) {
                    // toast.error();
                    toast.update(toastId, {
                        render: "AI bot is not available at the moment",
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000
                    });
                    return;
                }

                if (response?.isDone) {
                    dispatch(getUserUpdateThunk({ token }));
                }

                if (courseFromIndexedDb) {
                    dispatch(setCourseLessonMaterial({
                        courseIndex,
                        lessonIndex: index,
                        materialIndex: lesson.materials.length,
                        data: {
                            type: 'addon',
                            title: addonType,
                            content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                        }
                    }))
                }

                // dispatch(setCourseLessonAddon({
                //     courseIndex,
                //     lessonIndex: index,
                //     data: response.data
                // }))



                if (response.done) {
                    api.courses.uploadAddon({
                        data: {
                            type: 'addon',
                            title: addonType,
                            content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                        },
                        token
                    }).then(resp => {
                        if (courseFromIndexedDb) {
                            db.courses.update(Number(id), {
                                [`lessons.${index}.materials.${lesson.materials.length}`]: {
                                    id: resp.data.id,
                                    content: resp.data.content,
                                    type: resp.data.type,
                                    title: resp.data.title,
                                }
                            })
                            db.courses.update(Number(id), {
                                [`materials`]: [...courseFromIndexedDb.materials, resp.data.id]
                            })
                        } else {
                            dispatch(setCourseLessonMaterial({
                                courseIndex,
                                lessonIndex: index,
                                materialIndex: lesson.materials.length,
                                data: {
                                    ...resp.data,
                                    type: 'addon',
                                    title: addonType,
                                    content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                                }
                            }))
                            dispatch(setCourseMaterial({
                                courseIndex,
                                lessonIndex: index,
                                materialIndex: lesson.materials.length,
                                data: {
                                    ...resp.data,
                                    type: 'addon',
                                    content: response.data[addonType.replace(/ /g, '_').toLowerCase()]
                                }
                            }))
                        }
                    });
                    toast.update(toastId, {
                        render: "Material added",
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000
                    });
                }
            }
        });
    }

    const removeMaterial = async (id) => {
        try {
            const res = await api.courses.deleteCourseMaterial({
                id,
                token
            })
            if (res.success) {
                dispatch(deleteCourseMaterial({ id, courseIndex }));
                if (selectedMaterialValue && selectedMaterialValue?.id === id) {
                    setActiveBtn(null);
                    selectedMaterialValue(null);
                }
            }
        } catch (e) {

        }

    }
    const updateMaterial = async (id) => {
        if (!selectedMaterialValue?.content || selectedMaterialValue?.content?.trim() === '') {
            toast.error('Material content cannot be empty')
            return
        }
        const data = {
            content: selectedMaterialValue.content
        }
        try {
            const res = await api.courses.updateCourseMaterial({
                id,
                token,
                data
            })
            if (res.success) {
                dispatch(setUpdateCourseMaterial({ id, courseIndex, data: res.data }));
                setActiveBtn(null);
                selectedMaterialValue(null);
            }
        } catch (e) {

        }

    }

    useEffect(() => {
        if (!quill || !lesson.content) return;
        quill.disable();
        // quill.clipboard.dangerouslyPasteHTML(lesson.content, 'api');

        const contentToEditor = lesson.content;
        const delta = quill.clipboard.convert(contentToEditor);
        quill.setContents(delta);

    }, [quill, lesson.content]);


    const [initioaChartData, setInitioaChartData] = useState(null)
    useEffect(() => {
        if (activeBtn === 'edit') {
            const handelClick = ({ target }) => {
                if (target.classList.contains('chart-img')) {
                    target.classList.add('selected-chart')
                    setEditorActiveButton('chart')
                    setInitioaChartData(JSON.parse(target.getAttribute('data-chart')))
                }
            }
            document.addEventListener('dblclick', handelClick)
            return () => {
                document.removeEventListener('dblclick', handelClick)
            }
        }
    }, [activeBtn])

    const [editorActiveButton, setEditorActiveButton] = useState(null);

    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);

    useEffect(() => {
        if (!quill) return;
        if (activeBtn === 'edit') {
            quill.enable();
        } else {
            quill.disable();
        }
    }, [quill, activeBtn]);

    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [addonType, setAddonType] = useState('')

    return <li className={`goal-generator-card relative ${cardFullScreen === index ? 'h-full' : ''}`} ref={provided.innerRef} {...provided.draggableProps}>
        <div className={`goal-generator-card-header ${isOpen ? 'cursor-default' : 'cursor-pointer'}`} onClick={() => setIsOpen(true)}>
            <div className="gen-con-header-title gap-[8px]">
                <div {...provided.dragHandleProps} className={`p-[2px] card-drag-btn`}>
                    <DragIcon />
                </div>
                <div className='w-[16px] text-center text-[14px] text-[#A7A7A7]'>{index + 1}</div>
                <div className={`gen-header-arrow ${isOpen && 'rotate-180'}`} onClick={(e) => { e.stopPropagation(); setIsOpen(!isOpen) }}>
                    <ArrowIcon />
                </div>

                <span className="line-clamp-1" >{lesson.title}</span>
            </div>
            <div className="gen-con-header-date line-clamp-1">
                Generated {format(new Date(lesson.generated || lesson.createdAt), 'MM.dd.yyyy, H:mm')}
            </div>
        </div>
        <div className='accordion-content' aria-hidden={!isOpen}>
            <div className='overflow-hidden'>
                <div
                    id={'toolbar' + index}
                    className={classNames("editor-toolbar-column-wrpr flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] ql-toolbar ql-snow mx-[0px]")}
                    style={{
                        display: activeBtn === 'edit' ? 'flex' : 'none'
                    }}
                >
                    <EditorToolbar
                        isEdit={activeBtn === 'edit'}
                        quill={quill}
                        activeButton={editorActiveButton}
                        setActiveButton={setEditorActiveButton}
                        setImagePopupOpened={setImagePopupOpened}
                        setImageLinkOpened={setImageLinkOpened}
                        isFullScreen={cardFullScreen}
                        setCardFullScreen={setCardFullScreen}
                        editBlog={() => { }}
                        imageButtonRef={null}
                    />
                </div>
                <div
                    className="default-toolbar-wrpr flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B]"
                    style={{
                        display: activeBtn === 'edit' ? 'none' : 'flex'
                    }}
                >
                    <DefaultToolbar
                        disabled={false}
                        footerActions={footerActions}
                        setFooterActions={setFooterActions}
                        isFullScreen={cardFullScreen}
                        setCardFullScreen={setCardFullScreen}
                        editBlog={editLesson}
                        activeButton={activeBtn}
                        setActiveButton={setActiveBtn}
                        courseIndex={courseIndex}
                        index={index}
                    />
                </div>
                {isLoading ? <CardLoader /> : <>
                    <div className='max-w-[850px] lg:px-[16px] mx-auto my-[24px] flex flex-col gap-[18px]'>
                        <pre
                            ref={quillRef}
                            className={`max-h-[360px] no-scroll editor-id-${index}`}

                        // style={{display: isEdit ? 'block': 'none'}}
                        />
                    </div>
                    {activeBtn === 'edit' ? <EditorControl
                        activeButton={activeBtn}
                        setActiveButton={setActiveBtn}
                        editorActiveButton={editorActiveButton}
                        setEditorActiveButton={setEditorActiveButton}
                        quill={quill}
                        imageLinkOpened={imageLinkOpened}
                        setImageLinkOpened={setImageLinkOpened}
                        closeEditor={() => setActiveBtn(null)}
                        content={lesson.content}
                        courseIndex={courseIndex}
                        index={index}
                    /> : activeBtn === 'customPrompt' ? <div className='flex mb-[16px] gap-[10px]'>
                        <input
                            className='goal-generator-input'
                            value={customPrompt}
                            onChange={(e) => setCustomPrompt(e.target.value)}
                            placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                            style={{ height: "32px", padding: "8px 8px 8px 14px", fontSize: "13px" }}
                        />
                        <button
                            className='goal-generator-action btn--primary-gradient btn min-w-[65px]'
                            style={{ height: "32px", padding: "6px", fontSize: "13px" }}
                            onClick={() => {
                                customPromptSubmit();
                            }}
                        >
                            Apply
                        </button>
                    </div> : <div className='mb-[20px]'>
                        <div className='flex items-center gap-[12px]'>
                            <span>Materials</span>
                            <button
                                className='w-[26px] h-[26px] flex items-center justify-center rounded-full 
                            border border-solid border-[#383838] bg-[#1B1B1B]'
                                onClick={() => setActiveBtn('addLabel')}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.99578 12.9163C7.79078 12.9163 7.61502 12.8462 7.4685 12.7059C7.32196 12.5656 7.2487 12.3802 7.2487 12.1497V8.76634H3.83203C3.62786 8.76634 3.45182 8.69101 3.3039 8.54034C3.15599 8.38969 3.08203 8.21024 3.08203 8.00201C3.08203 7.77823 3.15599 7.5969 3.3039 7.45801C3.45182 7.31912 3.62786 7.24967 3.83203 7.24967H7.2487V3.83301C7.2487 3.61329 7.32291 3.43335 7.47133 3.29321C7.61975 3.15307 7.79642 3.08301 8.00133 3.08301C8.20624 3.08301 8.38481 3.15307 8.53703 3.29321C8.68925 3.43335 8.76536 3.61329 8.76536 3.83301V7.24967H12.1654C12.3743 7.24967 12.5543 7.32009 12.7054 7.46092C12.8565 7.60177 12.932 7.7813 12.932 7.99952C12.932 8.21776 12.8565 8.40012 12.7054 8.54661C12.5543 8.6931 12.3743 8.76634 12.1654 8.76634H8.76536V12.1497C8.76536 12.3802 8.68953 12.5656 8.53786 12.7059C8.38619 12.8462 8.20549 12.9163 7.99578 12.9163Z" fill="#A7A7A7" />
                                </svg>
                            </button>
                        </div>
                        <ul className='flex flex-col gap-[10px]'>
                            {lesson?.materials?.map((item, matherialIndex) =>
                                <li key={matherialIndex} className=''>
                                    <div className='flex items-center gap-[6px]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <rect x="4" y="6" width="10.6667" height="8" rx="2" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.2414 6.00001L11.7304 3.10201C11.6026 2.37683 10.911 1.89261 10.1858 2.02048L2.43404 3.38734C1.70886 3.51522 1.22465 4.20675 1.35251 4.93194L2.2619 10.0892C2.32329 10.4375 2.52052 10.7471 2.81019 10.9499C3.09986 11.1527 3.45824 11.2322 3.80649 11.1707L3.99871 11.1369" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {courseFromIndexedDb?.lessons?.[index]?.materials?.[matherialIndex]?.type === 'addon' ? <span className='text-[12px] text-[#A7A7A7]'>
                                            {courseFromIndexedDb?.lessons?.[index]?.materials?.[matherialIndex]?.title}
                                        </span> :
                                            item?.content || item?.title ? <span className='text-[12px] text-[#A7A7A7]'>
                                                {item?.type === "file" ? "File" : item.title}
                                            </span> : <span className='text-[12px] text-[#A7A7A7]'>
                                                {courseFromIndexedDb ? courseFromIndexedDb.materials_files.find(itemMaterial => +itemMaterial.data.id === +item)?.data?.title : '-'}
                                            </span>
                                        }
                                        <button
                                            className="blog-generator-button"
                                            onClick={() => {
                                                setSelectedMaterialValue(item)
                                                setActiveBtn('edit-matherial')
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M14.8688 5.60674L12.5063 3.26299L13.2375 2.51299C13.475 2.27549 13.7656 2.15674 14.1094 2.15674C14.4531 2.15674 14.7625 2.28174 15.0375 2.53174L15.6188 3.11299C15.8938 3.37549 16.0156 3.67861 15.9844 4.02236C15.9531 4.36611 15.8188 4.65674 15.5813 4.89424L14.8688 5.60674ZM3.1125 15.8817C2.85 15.8817 2.64062 15.8005 2.48438 15.638C2.32813 15.4755 2.25 15.2755 2.25 15.038V13.8755C2.25 13.7755 2.275 13.6661 2.325 13.5474C2.375 13.4286 2.43125 13.338 2.49375 13.2755L11.7375 4.05049L14.1 6.37549L4.89375 15.6192C4.79375 15.6942 4.6875 15.7567 4.575 15.8067C4.4625 15.8567 4.35625 15.8817 4.25625 15.8817H3.1125Z" fill="#A7A7A7"></path></svg>
                                        </button>
                                        <button
                                            className="blog-generator-button"
                                            onClick={() => { removeMaterial(item.id) }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.6554 16.7526H7.34102C6.45888 16.7526 5.72545 16.0734 5.6578 15.1939L4.93359 5.7793H15.0628L14.3386 15.1939C14.271 16.0734 13.5375 16.7526 12.6554 16.7526V16.7526Z" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.0011 5.77945H3.99609" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M7.89098 3.24707H12.1115C12.5777 3.24707 12.9556 3.62499 12.9556 4.09117V5.77938H7.04688V4.09117C7.04688 3.62499 7.42479 3.24707 7.89098 3.24707Z" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11.4767 9.15576V13.3763" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.52359 9.15576V13.3763" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </button>
                                    </div>
                                    <div className="flex-col">
                                        {courseFromIndexedDb?.lessons?.[index]?.materials?.[matherialIndex]?.type === 'addon' ?
                                            <span className='text-[14px] text-orange-primary underline'>
                                                {courseFromIndexedDb?.lessons?.[index]?.materials?.[matherialIndex]?.content}
                                            </span> :
                                            item?.type === "file" ? <a href={process.env.REACT_APP_API_URL + item.content} download={item.content} target='_blank' className='text-[14px] text-orange-primary underline '>{item.title}</a> :
                                            <MaterialItem 
                                                value={item} 
                                                activeBtn = {activeBtn} 
                                                setActiveBtn = {setActiveBtn}
                                                updateMaterial = {updateMaterial}
                                                setSelectedMaterialValue={setSelectedMaterialValue}
                                                selectedMaterialValue={selectedMaterialValue}
                                            />
                                        }
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>}
                    {activeBtn === 'addLabel' && <div className='flex items-center mb-[16px] gap-[10px] mob-flex-wrap'>
                        {labelInputState === 'input' && <>
                            <SelectBox
                                className='input-custom__wrap-input gen4-settings-select w-[200px]'
                                propsOptions={[
                                    { value: '', label: 'Select add-on type', placeholder: true },
                                    { value: 'Lesson Plan', label: 'Lesson Plan' },
                                    { value: 'Warm up', label: 'Warm up' },
                                    { value: 'Guided Questions', label: 'Guided Questions' },
                                    { value: 'Home Work', label: 'Home Work' },
                                    { value: 'Learning Materials', label: 'Learning Materials' },
                                ]}
                                value={addonType}
                                change={(topic) => setAddonType(topic)}
                            />
                            <input
                                className='goal-generator-input'
                                value={labelInput}
                                onChange={(e) => setLabelInput(e.target.value)}
                                placeholder="Write name for add-on that you will add..."
                            />
                            <button
                                className='goal-generator-action btn--primary-gradient btn'
                                onClick={() => {
                                    addMaterial();
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M10 4.16699V15.8337" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.16797 10H15.8346" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Add Material
                            </button>
                        </>}
                        {labelInputState === 'sugg' && <>
                            <ul className='flex flex-wrap gap-[8px] flex-1 items-center'>
                                <AwesomeIcon className='awesome-sugg' />
                                {labelSuggestions.map((item, index) => <li key={index}>
                                    <button
                                        className={`flex items-center gap-[6px] py-[5px] pl-[8px] pr-[5px] bg-[#202020] rounded-[24px] border border-solid box-border
                                    `}
                                        onClick={() => {
                                            setDraftLabels(labels => {
                                                if (labels.includes(item)) {
                                                    return labels.filter(label => label !== item);
                                                } else {
                                                    return [...labels, item];
                                                }
                                            })
                                        }}
                                    >
                                        <span className={`text-[12px] ${draftLabels.includes(item) ? 'text-orange-primary' : 'text-[#ffffff80]'}`}>
                                            {item}
                                        </span>
                                        {draftLabels.includes(item) ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_1618_15778)">
                                                <path d="M6.98242 9.11668L5.66576 7.78334C5.52353 7.65001 5.34847 7.58334 5.14057 7.58334C4.93267 7.58334 4.75218 7.65279 4.59909 7.79168C4.45464 7.94168 4.38242 8.12223 4.38242 8.33334C4.38242 8.54445 4.4602 8.71112 4.61576 8.83334L6.44909 10.6833C6.58546 10.8389 6.76121 10.9167 6.97636 10.9167C7.19151 10.9167 7.37687 10.8389 7.53242 10.6833L11.3158 6.90001C11.4713 6.75779 11.5491 6.5855 11.5491 6.38316C11.5491 6.18081 11.4722 5.99754 11.3184 5.83334C11.1647 5.70001 10.9807 5.63612 10.7666 5.64167C10.5525 5.64723 10.3744 5.71668 10.2324 5.85001L6.98242 9.11668ZM8.00139 15.0167C7.03719 15.0167 6.12549 14.8355 5.26631 14.4733C4.40711 14.111 3.65946 13.6118 3.02336 12.9757C2.38726 12.3396 1.88808 11.5928 1.52582 10.7351C1.16356 9.87744 0.982422 8.96651 0.982422 8.00231C0.982422 7.03526 1.16388 6.12367 1.52679 5.26753C1.88971 4.41138 2.38981 3.66419 3.02707 3.02596C3.66435 2.38771 4.41076 1.88494 5.26631 1.51762C6.12186 1.15032 7.03156 0.966675 7.99541 0.966675C8.96326 0.966675 9.87613 1.1498 10.734 1.51606C11.5919 1.88231 12.3394 2.38374 12.9767 3.02034C13.6139 3.65694 14.1158 4.40389 14.4825 5.26117C14.8491 6.11847 15.0324 7.03188 15.0324 8.00139C15.0324 8.9702 14.8488 9.88133 14.4815 10.7348C14.1142 11.5882 13.6114 12.3338 12.9731 12.9714C12.3349 13.6091 11.5885 14.1094 10.7339 14.4723C9.87926 14.8352 8.96843 15.0167 8.00139 15.0167Z" fill="#FF8114" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1618_15778">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g opacity="0.5">
                                                <path d="M8 3.33325V12.6666" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.33203 8H12.6654" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                        </svg>}
                                    </button>
                                </li>)}
                            </ul>
                            <button
                                className='goal-generator-action btn--primary-gradient btn'
                                onClick={() => {
                                    // setGenerateForm(prev => {
                                    //     const result = [...prev.goalMilestones];
                                    //     result[index] = {
                                    //         ...result[index],
                                    //         labels: [...result[index].labels, ...draftLabels]
                                    //     }
                                    //     return {
                                    //         ...prev,
                                    //         goalMilestones: result
                                    //     };
                                    // });
                                    setLabelInputState('input');
                                    setDraftLabels([]);
                                    setLabelInput('');
                                }}
                            >
                                Submit
                            </button>
                            <button
                                className='goal-generator-button-cross'
                                onClick={() => {
                                    setLabelInputState('input');
                                    setDraftLabels([]);
                                    setLabelInput('');
                                }}
                            >
                                <RefreshIcon />
                            </button>
                        </>}
                        <button
                            className='goal-generator-button-cross'
                            onClick={() => {
                                setActiveBtn(null);
                                setLabelInputState('input');
                                setDraftLabels([]);
                                setLabelInput('');
                            }}
                        >
                            <CrossIcon />
                        </button>
                    </div>}
                    {/* {activeBtn === 'edit-matherial' && <div className='flex items-center mb-[16px] gap-[10px] mob-flex-wrap'>
                        <textarea
                            className='goal-generator-input --type-textarea'
                            value={selectedMaterialValue.content}
                            rows={4}
                            // cols={4}
                            placeholder="Write name for add-on that you will add..."
                            onChange={({ target }) =>
                                setSelectedMaterialValue(prev => ({
                                    ...prev,
                                    content: target.value
                                })
                                )
                            }
                        />

                        <button
                            className='goal-generator-action btn--primary-gradient btn'
                            onClick={() => {
                                updateMaterial(selectedMaterialValue.id)
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 4.16699V15.8337" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.16797 10H15.8346" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Save Changes
                        </button>
                        <button
                            className='goal-generator-button-cross'
                            onClick={() => {
                                setActiveBtn(null);
                                setSelectedMaterialValue(null);
                            }}
                        >
                            <CrossIcon />
                        </button>
                    </div>} */}

                </>}
            </div>
        </div>
        {isOpen && activeBtn === 'deadline' && <div className='goal-generator-popup'>
            <p className='text-[18px] font-medium opacity-90 leading-[24px]'>Change Deadline</p>
            <p className='text-[14px] text-[#A7A7A7] opacity-60 mb-[24px] leading-[22px]'>Set date to change deadline</p>
            <DatePicker
                selected={formData.deadline}
                onChange={(deadline) => setFormData(prev => ({ ...prev, deadline }))}
                dateFormat="mm/dd/yyy"
                className='w-full'
                iconType='calendar'
            />
            <div className='flex gap-[12px] mt-[24px]'>
                <PrimaryButton
                    className='flex-1 goal-deadline-cancel'
                    onClick={() => setActiveBtn(null)}
                >
                    Cancel
                </PrimaryButton>
                <PrimaryButton
                    className='flex-1'
                    onClick={() => {
                        saveChanges();
                        setActiveBtn(null);
                    }}
                >
                    Submit
                </PrimaryButton>
            </div>
        </div>}

        {openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            {openedModal?.name === 'confirm-delete' && <div className="modal__body w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        {openedModal?.title ? openedModal.title : 'Do you really want to delete?'}
                    </h3>
                    {/* <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        Your plan “Publish a Novel” has been successfully saved
                    </p> */}
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div>}
        </GeneratedContentModal>}
        {imagePopupOpened && <div className='generated-blog-popup'>
            <input
                type="file"
                accept="image/png, image/jpeg"
                hidden
                id="blog-manual-image-input"
                multiple={false}
                onChange={async (e) => {
                    const files = e.target.files;
                    if (!files) return;
                    // const formData = new FormData();
                    // formData.append("attachments", file, file.name);
                    quill.focus();
                    try {
                        // const { data: { attachments }, success } = await api.assets.changeAsset({
                        //     id,
                        //     data: {
                        //         attachments: files
                        //     },
                        //     token
                        // })
                        // if(success) {
                        //     setImagePopupOpened(false);
                        //     setEditorActiveButton(null);
                        //     const range = quill.getSelection();
                        //     quill.insertEmbed(range.index, 'image', process.env.REACT_APP_API_URL + attachments[attachments.length - 1].path);
                        // }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            />
            <label htmlFor="blog-manual-image-input" className='py-[24px] px-[10px] flex flex-col justify-center items-center flex-1 rounded-[8px] bg-[#202020] cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="1.5" y="1.5" width="21" height="21" rx="5" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.3928 16.6596L19.2412 13.508C18.7856 13.0524 18.0469 13.0524 17.5913 13.508L14.9163 16.1831L9.90791 11.1747C9.4523 10.7191 8.71361 10.7191 8.258 11.1747L1.86426 17.5684" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.7094 7.71081L14.7099 7.7103C14.8237 7.59419 15.01 7.59222 15.1262 7.70589C15.2423 7.81957 15.2444 8.00588 15.1308 8.12213C15.0172 8.23838 14.8309 8.24057 14.7146 8.12704C14.5983 8.01351 14.596 7.82719 14.7094 7.71081" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='mt-[20px] text-[13px] font-medium opacity-90'>Upload Image</p>
                <p className='mt-[12px] text-[12px] font-light opacity-50'>Add image from your computer</p>
            </label>
            <button
                onClick={() => {
                    setImageLinkOpened(true);
                    setImagePopupOpened(false);
                }}
                className='py-[24px] px-[10px] flex flex-col justify-center items-center
                flex-1 rounded-[8px] bg-[#202020]'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M19.2239 16.2887L21.7811 13.7315C23.8119 11.6495 23.7911 8.32164 21.7346 6.26509C19.6781 4.20855 16.3502 4.18781 14.2682 6.21856L11.7109 8.77579" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.1123 10.8867L10.8867 17.1123" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.77579 11.7109L6.21856 14.2682C4.18781 16.3502 4.20855 19.6781 6.26509 21.7346C8.32164 23.7911 11.6495 23.8119 13.7315 21.7811L16.2887 19.2239" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className='mt-[20px] text-[13px] font-medium opacity-90'>Add Image URL</p>
                <p className='mt-[12px] text-[12px] font-light opacity-50'>Paste image link to add picture</p>
            </button>
        </div>}
        {editorActiveButton === 'chart' &&
            <AddEditorChartModal
                handelClose={() => {
                    setEditorActiveButton(null);
                    setInitioaChartData(null)
                }}
                isOpened={editorActiveButton === 'chart'}
                editor={quill}
                initialData={initioaChartData}
            />}
    </li>;
};

export default LessonCard;
import React, { useEffect, useState } from 'react'

import arrowSvg from '../../assets/img/arrow-drop-down.svg'

const NumberInput = ({ value, change, placeholder, className, onChange, min = 0 }) => {
    const [actual, setActual] = useState(value)
    useEffect(() => {
        setActual(value)
    }, [value])
    useEffect(() => {
        if(actual < min){
            setActual(min);
            return;
        }

        onChange && onChange(actual)
    }, [actual])

    return (
            <div className={`input-custom__wrap-input number-input ${className}`}>
                <input className="input-custom__input" type="number" min={1} placeholder={placeholder} value={actual} onChange={e => setActual(+e.target.value)}/>
                <div className="arrows-group-right">
                    <button onClick={() => setActual(actual + 1)} className="arrow-up" style={{height: '18px'}}>
                        {/* <img src={arrowSvg} style={{display: 'initial'}}/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                            <path d="M5.19474 7.49531L0.928078 3.26198C0.439189 2.75087 0.330855 2.19531 0.603078 1.59531C0.8753 0.995313 1.3503 0.695312 2.02808 0.695312H10.4614C11.117 0.695312 11.5809 0.995313 11.8531 1.59531C12.1253 2.19531 12.017 2.75087 11.5281 3.26198L7.29474 7.49531C7.1503 7.62865 6.98641 7.72865 6.80308 7.79531C6.61974 7.86198 6.42808 7.89531 6.22808 7.89531C6.00586 7.89531 5.80863 7.86198 5.63641 7.79531C5.46419 7.72865 5.31697 7.62865 5.19474 7.49531Z" fill="white" fillOpacity="0.12"/>
                        </svg>
                    </button>
                    <button onClick={() => {if(actual === 1) return; setActual(actual - 1)}} className="arrow-down" style={{height: '18px'}}>
                        {/* <img src={arrowSvg} style={{display: 'initial'}}/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                            <path d="M5.19474 7.49531L0.928078 3.26198C0.439189 2.75087 0.330855 2.19531 0.603078 1.59531C0.8753 0.995313 1.3503 0.695312 2.02808 0.695312H10.4614C11.117 0.695312 11.5809 0.995313 11.8531 1.59531C12.1253 2.19531 12.017 2.75087 11.5281 3.26198L7.29474 7.49531C7.1503 7.62865 6.98641 7.72865 6.80308 7.79531C6.61974 7.86198 6.42808 7.89531 6.22808 7.89531C6.00586 7.89531 5.80863 7.86198 5.63641 7.79531C5.46419 7.72865 5.31697 7.62865 5.19474 7.49531Z" fill="white" fillOpacity="0.12"/>
                        </svg>
                    </button>
                </div>
            </div>
    )
}

export default NumberInput;